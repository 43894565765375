import {
    LOCAL_STORAGE_APP_KEY,
    LS_AB_MIXPANEL_SENT,
} from "../constants/LocalStorage";
import {CURRENT_ACTIVE_AB_TEST, WEVIDEO_PLANS} from "../constants/AppGlobal";
import DataStore from "./DataStore";
import URLHelper from "./URLHelper";
import Track from "../services/track/Track";

export const scrollToByElementId = (id, event = null) => {
    if(event !== null) {
        event.preventDefault();
        event.stopPropagation();
    }

    if(!document.getElementById(id)) {
        return;
    }

    const bodyRect = document.body.getBoundingClientRect(),
        elemRect = document.getElementById(id).getBoundingClientRect();
    let offset   = elemRect.top - bodyRect.top;

    /* istanbul ignore else */
    if(document.getElementById("Header")) {
        const headerRect = document.getElementById("Header").getBoundingClientRect();
        offset -= headerRect.height;
    }
    // weird browsers do not support scrollTo with {options} as param
    try {
        window.scrollTo({ top: offset, behavior: 'smooth' });
    } catch (e) {
        window.scrollTo(0, offset);
    }
};

const getAppValue = (key) => {
	const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);
	if(!app) {
		return null;
	}

	return app[key]
};

export const isAuth = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return;

    return app.isAuth;
};

export const isUserEnterpriseMember = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.isUserEnterpriseMember;
};

export const getEnterpriseMemberInstance = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('enterpriseMemberInstance') ? app.enterpriseMemberInstance : '';
};

export const getSSOType = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return '';

    return app.ssoType;
};

export const getCanEditSSOCredentials = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.canEditSSOCredentials;
};

export const getCanEditCredentials = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return true;

    return app.ssoType;
};

export const activationRequired = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return;

    return app.activationRequired;
};

export const getVertical = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 'unknown';

    return app.hasOwnProperty('vertical') ? app.vertical : 'unknown';
};

export const getUserUserName = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return '';

    return app.hasOwnProperty('userName') ? app.userName : '';
};

export const getUserFirstName = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return '';

    return app.hasOwnProperty('firstName') ? app.firstName : '';
};

export const getUserLastName = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return '';

    return app.hasOwnProperty('lastName') ? app.lastName : '';
};

export const getUserEmail = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return '';

    return app.hasOwnProperty('userEmail') ? app.userEmail : '';
};

export const isGdprRegion = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.isGdprRegion ? app.isGdprRegion : false;
};


export const isClientUs = () => {
	 const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);
	 if(!app) return false;

	 return app.isClientUs ? app.isClientUs : false;
};

export const isEnterpriseAdmin = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isEnterpriseAdmin') ? app.isEnterpriseAdmin : false;
};

export const isTrial = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isTrial') ? app.isTrial : false;
};

/**
 * This function returns true only for business and education trials
 * For professional trial use getProfessionalTrial function
 *
 * @returns {boolean|*}
 */
export const isMemberTrial = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isMemberTrial') ? app.isMemberTrial : false;
};

export const userHasPaymentMethodSet = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('userHasPaymentMethodSet') ? app.userHasPaymentMethodSet : false;
};

export const getUserHasTrialProduct = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('userHasTrialProduct') ? app.userHasTrialProduct : false;
}

export const hasVisitedNewHub = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('visitedNewHub') && app.visitedNewHub === "true" ? app.visitedNewHub : false;
};

export const getProductCode = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('productCode') ? app.productCode : WEVIDEO_PLANS.FREE_PLAN;
};

export const getPurchasableProducts = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('purchasableProducts') ? app.purchasableProducts : '';
};

export const getPurchasableTrialProducts = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('purchasableTrialProducts') ? app.purchasableTrialProducts : '';
};

export const accountUpgradesDisabled = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('disableAccountUpgrades') ? app.disableAccountUpgrades : false;
};

export const hasAndroidSubscription = () => {
	var val = getAppValue("androidSubscription");
	return val ? val : false;
};

export const hasItunesSubscription = () => {
	var val = getAppValue("itunesSubscription");
	return val ? val : false;
};

export const shouldViewUpgrade = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('shouldViewUpgrade') ? app.shouldViewUpgrade : false;
};

export const getTermDurationMonths = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('termDurationMonths') ? app.termDurationMonths : 1;
};

export const useNewPlans = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('useNewPlans') ? app.useNewPlans : false;
};

// this applies to free_users, (trial owners that sign-up are included, once signed up, on the upgrade paths, they will not be included)
// trial members that join through invitation, are NOT considered free users
export const userHasFreeProduct = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('freeProduct') ? app.freeProduct : false;
};

// this applies to free_users, (trial owners that sign-up are included, once signed up, on the upgrade paths, they will not be included)
// trial members that join through invitation, are NOT considered free users
export const isFreeUser = () => {
    return userHasFreeProduct();
};

export const getUserUpgradePayMode = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return '';

    return app.hasOwnProperty('userUpgradePayMode') ? app.userUpgradePayMode : '';
};

export const getDeploymentEnv = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return '';

    return app.hasOwnProperty('deploymentEnv') ? app.deploymentEnv : '';
};

export const getUserId = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 0;

    return app.hasOwnProperty('userId') ? app.userId : 0;
};

export const useHTMLEditor = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('useHTMLEditor') && (app.useHTMLEditor === "true" || app.useHTMLEditor === true);
};

export const getInstanceId = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 0;

    return app.hasOwnProperty('instanceId') ? app.instanceId : 0;
};

export const getOrderState = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "unknown";

    return app.hasOwnProperty('orderState') ? app.orderState : "unknown";
};

export const getOrderEndDate = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return -1;

    return app.hasOwnProperty('orderEndDate') ? app.orderEndDate : -1;
};

export const accountIsPilot = () => {
    return getOrderState() === 'pilot';
};

export const accountIsPromo = () => {
    return getOrderState() === 'promo';
};

export const getEnterpriseRole = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "";

    return app.hasOwnProperty('enterpriseRole') ? app.enterpriseRole : "";
};

export const getSKUTerm = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "";

    return app.hasOwnProperty('skuTerm') ? app.skuTerm : "";
};

export const getPriceTestingGroup = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('priceTestingGroup') ? app.priceTestingGroup : null;
};

export const getPriceTestingGroupValue = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('priceTestingGroupValue') ? app.priceTestingGroupValue : null;
};

export const getPriceCampaignKey = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('priceCampaignKey') ? app.priceCampaignKey : null;
};


export const getDefaultEditingMode = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "unknown";

    return app.hasOwnProperty('defaultEditingMode') ? app.defaultEditingMode : "unknown";
};

export const getEmailVerified = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('emailVerified') ? app.emailVerified : false;
};

export const getDesiredInstanceName = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "";

    return app.hasOwnProperty('desiredInstanceName') ? app.desiredInstanceName : "";
};

export const isInHub = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return true;

    return app.hasOwnProperty('isInHub') ? app.isInHub : true;
};

export const showCampaignBanner = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return true;

    return app.hasOwnProperty('showCampaignBanner') ? app.showCampaignBanner : true;
};

export const getUserPrivacyPreferences = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('privacyPreferences') ? app.privacyPreferences : [];
};

export const getSeatPrice = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('seatPrice') ? app.seatPrice : null;
};

export const getCurrentSeats = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 0;

    return app.hasOwnProperty('currentSeats') ? app.currentSeats : 0;
};

export const getMaxSeats = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 0;

    return app.hasOwnProperty('maxSeats') ? app.maxSeats : 0;
};

export const getTargetedPricingCampaign = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 0;

    return app.hasOwnProperty('targetedPricingCampaign') ? app.targetedPricingCampaign : '';
};

export const getUserEssentialsInfo = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('essentialsInfo') ? app.essentialsInfo : null;
};

export const getRemainingTrialDays = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 0;

    return app.hasOwnProperty('remainingTrialDays') ? app.remainingTrialDays : 0;
};

export const getProfessionalTrial = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('professionalTrial') ? app.professionalTrial : false;
};

export const getIsExpiredTrial = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isExpiredTrial') ? app.isExpiredTrial : false;
};

export const isExpiredAccount = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isExpiredAccount') ? app.isExpiredAccount : false;
};

export const isPausedAccount = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isPausedAccount') ? app.isPausedAccount : false;
};

export const getUserWithoutActiveLicense = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isUserWithoutActiveLicense') ? app.isUserWithoutActiveLicense : false;
};

export const getInstanceProduct = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('hasInstanceProduct') ? app.hasInstanceProduct : false;
};

export const accountIsPurchaseOrder = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('isPurchaseOrder') ? app.isPurchaseOrder : false;
};

export const getSessionPropertiesSignUpPage = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('sessionPropertiesSignUpPage') ? app.sessionPropertiesSignUpPage : null;
};

export const getSessionPropertiesPageUrl = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('sessionPropertiesPageUrl') ? app.sessionPropertiesPageUrl : null;
};

export const getSelectedBuyContext = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "";

    return app.hasOwnProperty('selectedBuyContext') ? app.selectedBuyContext : "";
};

// this checks also the URL parameters from props history
export const getBuyContextWithURLParamsAndSessionCheck = (props) => {
    const buyContext = URLHelper.getUrlParamValue(props, 'p');
    if(buyContext !== null) {
        return buyContext;
    }

    return getSelectedBuyContext()
};

export const getSelectedProduct = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "";

    return app.hasOwnProperty('selectedProduct') ? app.selectedProduct : "";
};

// this checks also the URL parameters from props history
export const getProductCodeWithURLParamsAndSessionCheck = (props) => {
    const productCode = URLHelper.getUrlParamValue(props, 'product');
    const plan = URLHelper.getUrlParamValue(props, 'plan');
    if(productCode !== null) {
        return productCode;
    } else if(plan !== null) {
        return plan;
    }

    const selectedProduct = getSelectedProduct();

    return selectedProduct !== "" ? selectedProduct : WEVIDEO_PLANS.FREE_PLAN;
};

export const getSelectedTier = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "";

    return app.hasOwnProperty('selectedTier') ? app.selectedTier : "";
};

// this checks also the URL parameters from props history
export const getTierWithURLParamsAndSessionCheck = (props) => {
    const tier = URLHelper.getUrlParamValue(props, 'tier');
    if(tier !== null) {
        return tier;
    }

    return getSelectedTier();
};

export const getUserPurpose = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return "";

    return app.hasOwnProperty('userPurpose') ? app.userPurpose : "";
};

export const getAccountType = (sub) => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);
    let name = "";

    if(!app) return name;

    if(!isAuth()) {
        return name;
    }

    name = sub !== undefined ? sub : app.hasOwnProperty('accountType') ? app.accountType : "";

    if (name.indexOf("Enterprise") !== -1) {
        name = "Enterprise";
    } else if (name.indexOf("Ultra") !== -1) {
        /* istanbul ignore else */
        if (name.indexOf("Education") !== -1) {
            name = "WeVideo Ultra Education";
        } else if (name.indexOf("Business") !== -1) {
            name = "WeVideo Ultra Business";
        }
    } else if (name.indexOf("Plus") !== -1) {
        if (name.indexOf("Education") !== -1) {
            name = "WeVideo Plus Education";
        } else if (name.indexOf("Business") !== -1) {
            name = "WeVideo Plus Business";
        } else if (name.indexOf("Premium") !== -1) {
            name = "Premium Plus";
        } else {
            name = "Plus";
        }
    } else if (name.indexOf("Commercial") !== -1) {
        name = "Commercial";
    } else if (name.indexOf("Lite") !== -1) {
        if (name.indexOf("Education") !== -1) {
            name = "WeVideo Lite Education";
        } else if (name.indexOf("Business") !== -1) {
            name = "WeVideo Lite Business";
        } else {
            name = "Lite";
        }
    } else if (name.indexOf("Personal HD") !== -1) {
        name = "Personal HD";
    } else if (name.indexOf("Personal") !== -1) {
        name = "WeVideo Personal";
    } else if (name.indexOf("Business T") !== -1) {
        name = "WeVideo Business Team";
    } else if (name.indexOf("Business") !== -1) {
        name = "WeVideo Business";
    } else if (name.indexOf("Higher") !== -1) {
        name = "WeVideo Higher Education";
    } else if (name.indexOf("Education") !== -1) {
        if (name.indexOf("Free") !== -1) {
            name = "Free education";
        } else {
            name = "WeVideo Education";
        }
    } else if (name.indexOf("WeVideo Free") !== -1) {
        name = "WeVideo Free";
    } else if (name.indexOf("WeVideo Grace") !== -1) {
        name = "WeVideo Grace";
    } else if (name.indexOf("internal") !== -1) {
        name = "WeVideo Family";
    } else if (name.indexOf("Schools") !== -1) {
        name = "WeVideo Schools";
    } else if (name.indexOf("WeVideo Pro") !== -1) {
        name = "WeVideo Pro";
    }

    if (getOrderState() === "trial") {
        name += " Trial";
    }

    return name;
};

export const getUsedSeats = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return 0;

    return app.hasOwnProperty('usedSeats') ? app.usedSeats : 0;
};

export const getLastOrderId = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('lastOrderId') ? app.lastOrderId : null;
};

export const userIsPreviousTrialOwner = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('previousTrialOwner') ? app.previousTrialOwner : false;
}

export const updateApp = (newProperties) => {
	return DataStore.getInstance().updateObject(LOCAL_STORAGE_APP_KEY, newProperties);
};

export const getApp = () => {
	return DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY) || {};
};

// this type of users are either free accounts joining instances, provisioned accounts (payed in advance)
// pilot users joining instances or owners activating their account, or promo accounts
export const userShouldNotSeeThePaymentForm = () => {
    return isUserEnterpriseMember() || accountIsPurchaseOrder() || accountIsPilot() || accountIsPromo();
};

/*
 * For local storage settings we mix the user ID in with the key, to avoid properties
 * leaking between users (leaking in terms of functionality, not security...)
 */
export const setLocalSetting = (key, value) => {
	localStorage.setItem(key + "_" + getApp().userId, value);
};

export const getLocalSetting = (key) => {
	return localStorage.getItem(key + "_" + getApp().userId);
};

// if its a payed account, we should show the receipt on the first segmentation step
// free account do not see the checkout summary, and they dont have a receipt to show
export const showReceiptOnFirstSegmentationPage = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('showReceiptOnFirstSegmentationPage') ? app.showReceiptOnFirstSegmentationPage : false;
}

export const getShouldSeeSegmentation = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('shouldSeeSegmentation') ? app.shouldSeeSegmentation : false;
};

export const getBelongsToPlayPositSpace = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('belongsToPlayPositSpace') ? app.belongsToPlayPositSpace : false;
};

export const showPremiumTemplates = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('showPremiumTemplates') ? app.showPremiumTemplates : false;
}

// this is an array of product codes that is passed through the URL
// if the list contains `all`, then no display logic is needed
export const premiumTemplateProducts = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return null;

    return app.hasOwnProperty('premiumTemplateProducts') ? app.premiumTemplateProducts : null;
}

export const getInvalidTeacherEmail = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if (!app) return false;
    return app.hasOwnProperty('invalidTeacherEmail') ? app.invalidTeacherEmail : false;
}

export const getTeacherEmailWasValidated = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if (!app) return false;
    return app.hasOwnProperty('teacherEmailWasValidated') ? app.teacherEmailWasValidated : false;
}

export const getInvalidEduEmail = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if (!app) return false;
    return app.hasOwnProperty('invalidEduEmail') ? app.invalidEduEmail : false;
}

export const getUserSegmentationInfo = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if (!app) return null;
    return app.hasOwnProperty('segmentationInfo') ? app.segmentationInfo : null;
}

export const setLocalStorageSegmentationInfo = (segmentationInfo) => {
    localStorage.setItem('segmentationInfo', JSON.stringify(segmentationInfo));
}

export const getLocalStorageSegmentationInfo = () => {
    const segmentationInfo = localStorage.getItem('segmentationInfo');

    if (!segmentationInfo) return null;
    
    return JSON.parse(segmentationInfo);
}

export const getUserIsSegmentedAsTeacherK12 = () => {
    const segmentationInfo = getUserSegmentationInfo();
    // if we have no segmentation info, this will be null - for users who just sign up
    if (segmentationInfo !== null) {
        if (segmentationInfo.hasOwnProperty('segment') && segmentationInfo.hasOwnProperty('segmentDetail')) {
            const job = segmentationInfo.segmentDetail;
            if (segmentationInfo.segment === 'edu' && (job === 'k12_teacher' || job === 'k12_coach' || job === 'k12_leadership')) {
                return true;
            }
        }
    }
    return false;
}

export const getCCRequiredForTrial = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('ccRequiredForTrial') ? app.ccRequiredForTrial : false;
};

export const getUserCountryCode = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('userCountryCode') ? app.userCountryCode : null;
};

export const getUserStateCode = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('userStateCode') ? app.userStateCode : null;
};

export const getUserStateName = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('userStateName') ? app.userStateName : null;
};

export const getAbTestVariation = (testName) => {
    const activeTests = getActiveTests();
    let eventVariation = false;
    if (activeTests && activeTests.length > 0) {
        activeTests.forEach(function(item, index) {
            if (item.hasOwnProperty('name') && item.hasOwnProperty('data') && item.data.hasOwnProperty('value')) {
                if (item.name === testName || item.name === CURRENT_ACTIVE_AB_TEST) {
                    eventVariation = item.data.value;
                }
            }
        });
    }

    return eventVariation;
}

export const abTestIsVariation = () => {
    return getAbTestVariation(CURRENT_ACTIVE_AB_TEST) === "variation1";
}

export const sendActiveAbTestStartEvents = (page, testName) => {
    const AB_TEST_SENT_KEY = LS_AB_MIXPANEL_SENT + "_" + testName;

    const abTestSent = sessionStorage.getItem(AB_TEST_SENT_KEY);

    const variationName = getAbTestVariation(testName);
    if (variationName !== false && abTestSent !== "true") {
        sessionStorage.setItem(AB_TEST_SENT_KEY, "true");
        Track.indicative({
            "event": "$experiment_started",
            "data": {
                "Experiment name": testName,
                "Variant name": variationName,
                "page": page
            },
        });
    }
}
export const getActiveTests = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if (!app) return false;
    return app.hasOwnProperty('activeTests') ? app.activeTests : [];
}

export const hasGtmEnabled = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return true;

    return app.hasOwnProperty('hasGtmEnabled') ? !!app.hasGtmEnabled : true;
}
export const hasFeideLogin = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return false;

    return app.hasOwnProperty('feideLogin') ? !!app.feideLogin : false;
}
