import {RECAPTCHA_SITE_KEY_DEV, RECAPTCHA_SITE_KEY_PROD, WEVIDEO_PLANS} from "../constants/AppGlobal";
import {isObjectEmpty, isProduction, loadedInIframe} from "./GeneralHelper";
import CurrentProduct from "../models/CurrentProduct";
import {
    PLAN_SELECTED_TIER_BUSINESS,
    PLAN_SELECTED_TIER_EDUCATION,
    PLAN_SELECTED_TIER_PROFESSIONAL, PLAN_SELECTED_TIER_UNLIMITED
} from "../constants/Plans";
import URLHelper from "./URLHelper";
import {isAnnualProfessionalPlan, isBusinessPlan, isClassroomPlan} from "./PlansHelper";
import DataStore from "./DataStore";
import {LOCAL_STORAGE_APP_KEY} from "../constants/LocalStorage";

export const setIsEnterpriseAdmin = (user) => {
    return user['enterpriseAccountOwner'];
};

export const isMemberTrial = (license, orderState) => {
    return (typeof license !== "undefined" && license["state"] === "trial") || orderState === "trial";
};

export const getOrderState = (products) => {
    if(!products.hasOwnProperty('orderState')){
        return "unknown";
    }

    var ret = products.orderState;
    if(ret === "pilot" && products.covidPromotionOrder){
        /* COVID-19 promo orders are a special case, and for the checkout app they work like a trial */
        ret = "trial";
    }

    return ret;
};

export const getOrderEndDate = (products) => {
    return products.hasOwnProperty('orderEndDate') ? products.orderEndDate : -1;
};

export const isUserEnterpriseMember = (user) => {
    return (
        user.hasOwnProperty('enterpriseMember')
        && user.enterpriseMember
    ) && (
        user.hasOwnProperty('enterpriseAccountOwner')
        && !user.enterpriseAccountOwner
    );
};

export const setEnterpriseMemberInstance = (user) => {
    if (user.hasOwnProperty('enterpriseMemberInstance')) {
        let instanceName = user.enterpriseMemberInstance;
        if (instanceName.indexOf('_by_') !== -1) {
            return instanceName.substr(0, instanceName.indexOf('_by_'));
        }
        return instanceName;
    }
    return '';
};

export const userUserName = (user) => {
    return user.hasOwnProperty('username') ? user.username : '';
};


export const setUserId = (user) => {
    return user.hasOwnProperty('userId') ? user.userId : 0;
};

export const userFirstName = (user) => {
    return user.hasOwnProperty('firstName') ? user.firstName : '';
};

export const userLastName = (user) => {
    return user.hasOwnProperty('lastName') ? user.lastName : '';
};

export const activationRequired = (user) => {
    return user.activationRequired;
};

export const getVertical = (products) => {
    return products.hasOwnProperty('Vertical') ? products.Vertical : '';
};

export const getPreviousTrialOwner = (products) => {
    return products.hasOwnProperty('previousTrialOwner') ?  products.previousTrialOwner : false;
};

export const getPaypalSubscriptionId = (user) => {
    return user.hasOwnProperty('properties') &&  user.properties.hasOwnProperty('paypalSubscriptionId');
};

// emulate the CartSummary.userHasPaypalPayment function from product code
export const userHasPayPalPayment = (user, productInfo) => {
    if(
        (user.hasOwnProperty('properties') && user.properties.hasOwnProperty('paypalSubscriptionId')) ||
        (productInfo.hasOwnProperty("paypalTransactions") && productInfo.paypalTransactions !== null)
    ) {
        if(user.hasOwnProperty('properties') && user.properties.hasOwnProperty('stripeId')) {
            return productInfo.hasOwnProperty("paymentMethod") && productInfo.paymentMethod === "paypal"
        }
        return true
    }

    return false;
};

export const setSelectedBuyContext = (user) => {
    return user.hasOwnProperty('properties')
        &&  user.properties.hasOwnProperty('selectedP')
        ? user.properties.selectedP : "";
};

export const setSelectedProduct = (user) => {

    if(user.hasOwnProperty('properties')) {
        if(user.properties.selectedP === "trial" && user.properties.hasOwnProperty('selectedTier')) {
            // eslint-disable-next-line default-case
            switch(user.properties.selectedTier) {
                case PLAN_SELECTED_TIER_UNLIMITED:
                    return WEVIDEO_PLANS.UNLIMITED_PLAN_TRIAL;
                case PLAN_SELECTED_TIER_PROFESSIONAL:
                    return WEVIDEO_PLANS.PROFESSIONAL_TRIAL;
                case PLAN_SELECTED_TIER_BUSINESS:
                    return WEVIDEO_PLANS.BUSINESS_PLAN_TRIAL;
                case PLAN_SELECTED_TIER_EDUCATION:
                    return WEVIDEO_PLANS.EDUCATION_TRIAL;
            }
        }
    }

    return user.hasOwnProperty('properties')
            &&  user.properties.hasOwnProperty('selectedProduct')
                ? user.properties.selectedProduct : "";
};

export const setSelectedTier = (user) => {
    return user.hasOwnProperty('properties')
        &&  user.properties.hasOwnProperty('selectedTier')
        ? user.properties.selectedTier : "";
};

export const setUserPurpose = (user) => {
    return user.hasOwnProperty('properties')
        &&  user.properties.hasOwnProperty('purpose')
        ? user.properties.purpose : "";
};

export const setSessionPropertiesSignUpPage = (user) => {
    return user.hasOwnProperty('sessionProperties')
    && user.sessionProperties.hasOwnProperty('signupLocation')
        ?  user.sessionProperties['signupLocation']: null;
};

export const setSessionPropertiesPageUrl = (user) => {
    return user.hasOwnProperty('sessionProperties')
        && user.sessionProperties.hasOwnProperty('page_url')
        ?  user.sessionProperties['page_url']: null;
};

export const setSSOType = (user) => {
    return user.hasOwnProperty('ssoType') ? user.ssoType : "";
};

export const setCanEditSSOCredentials = (instanceProperty) => {
    return instanceProperty.hasOwnProperty('canEditSSOCredentials') &&
        (
            instanceProperty.canEditSSOCredentials === "true"
            || instanceProperty.canEditSSOCredentials === true
        );
};

export const setCanEditCredentials = (instanceProperty) => {
    return instanceProperty.canEditCredentials
};

export const userHasPaymentMethodSet = (user, productInfo) => {
    return (
            user.hasOwnProperty('properties') &&
            (
                user.properties.hasOwnProperty('stripeId')
                || user.properties.hasOwnProperty('paypalSubscriptionId')
            )
        ) ||
        (
            productInfo.hasOwnProperty("paypalTransactions") && productInfo.paypalTransactions !== null
        );
};

export const userHasTrialProduct = (user, productsInfo) => {
    if (productsInfo.hasOwnProperty('TrialProduct') && productsInfo['TrialProduct'] === "true") {
        return true;
    }
    if (user.hasOwnProperty('properties') && user.properties.hasOwnProperty('selectedP')) {
        return user.properties.selectedP === 'trial';
    }
    return false;
}

export const userEmail = (user) => {
    return user.hasOwnProperty('email') ? user.email : '';
};

export const visitedNewHub = (user) => {
    return user.hasOwnProperty('properties') &&  user.properties.hasOwnProperty('visitedNewHub') ? user.properties.visitedNewHub : false;
};

export const getProductCode = (productsInfo) => {
    return productsInfo.hasOwnProperty('productCode') ? productsInfo.productCode : WEVIDEO_PLANS.FREE_PLAN;
};

export const setPurchasableProducts = (productsInfo) => {
    let purchasableProducts = productsInfo['Purchasable Products'] || '';
    let productCode = getProductCode(productsInfo);
    if (productCode === "wv_biz") {
        // legacy unlimited product code, needs to be able to buy extra seats
        purchasableProducts += ',wv_biz';
    }
    // we allow users to change tier now, but only to buy the teacher plan and if they are not business or professional annual
    if (
        purchasableProducts && 
        !isBusinessPlan(productCode) && 
        !isAnnualProfessionalPlan(productCode) && 
        !isClassroomPlan(productCode)
    ) {
        purchasableProducts += ',' + WEVIDEO_PLANS.EDUCATION_TEACHER_PLAN_Y;
    }
    purchasableProducts = purchasableProducts.replace(" ", "");

    return purchasableProducts;
};

export const setPurchasableTrialProducts = (productsInfo) => {
    return productsInfo.hasOwnProperty('Purchasable Products Trial') ? productsInfo['Purchasable Products Trial'] : '';
};

export const disableAccountUpgrades = (productsInfo) => {
    return productsInfo.hasOwnProperty('disableAccountUpgrades') ? productsInfo.disableAccountUpgrades : false;
};

export const shouldViewUpgrade = (user, hasUpgradeDisabled) => {
    return activationRequired(user) === false && !hasUpgradeDisabled;
};

export const getTermDurationMonths = (productsInfo) => {
    return productsInfo.hasOwnProperty('TermDurationMonths') ? parseInt(productsInfo['TermDurationMonths']) : 1;
};

export const utilizeNewPlans = (user) => {
    return user.hasOwnProperty('properties') &&  user.properties.hasOwnProperty('useNewPlans') ? user.properties.useNewPlans : false;
};

export const setDesiredInstanceName = (user) => {
    return user.hasOwnProperty('properties') &&  user.properties.hasOwnProperty('desiredInstanceName') ? user.properties.desiredInstanceName : "";
};

export const setTargetedPricingCampaign = (user) => {
    return user.hasOwnProperty('properties') &&  user.properties.hasOwnProperty('targetedPricingCampaign') ? user.properties.targetedPricingCampaign : '';
};

export const setEmailVerified = (user) => {
    return user.hasOwnProperty('emailVerified') ? user.emailVerified : false;
};

export const setAccountType = (productsInfo) => {
    return productsInfo.hasOwnProperty('accountType') ? productsInfo.accountType : "";
};

export const setRemainingTrialDays = (productsInfo, license) => {
    let remainingTrialDays = 0;

    if(productsInfo && (productsInfo.productCode.indexOf("wv_bizindiv") !== -1 || productsInfo.productCode.indexOf("wv_bizoct17") !== -1) && productsInfo['TrialProduct'] === "true") {
        let endDate = parseInt(productsInfo["orderEndDate"], 10),
            now = new Date();
        remainingTrialDays = Math.floor( (endDate - now.getTime()) / (24*60*60*1000));
    } else if(license && license.hasOwnProperty('remainingDays')) {
        remainingTrialDays = license['remainingDays'];
    }

    return remainingTrialDays;
};

export const setProfessionalTrial = (productsInfo) => {
    return productsInfo.hasOwnProperty("productCode") && productsInfo.productCode.indexOf("wv_bizindiv") !== -1 && productsInfo['TrialProduct'] === "true";
};

export const setExpiredTrial = (productsInfo, license, instanceProperty, isTrial) => {

    if(productsInfo.hasOwnProperty('orderState') && productsInfo.orderState === "automatic") {
        return false;
    }

    if(isObjectEmpty(productsInfo) || (productsInfo.hasOwnProperty('expiredAccount') && productsInfo.expiredAccount)) {
        return true;
    }

    if(instanceProperty.hasOwnProperty('EXPIRED_TRIAL') && instanceProperty['EXPIRED_TRIAL'] === "true") {
        return true;
    }

    if(isTrial && !isObjectEmpty(license)) {
        return license.hasOwnProperty("state")
            && license.state !==  "automatic"
            && license.hasOwnProperty('remainingDays')
            && license.remainingDays <= 0;
    }

    if(
        productsInfo.hasOwnProperty("productCode")
        && productsInfo.productCode.indexOf("wv_bizindiv") !== -1
        && productsInfo['TrialProduct'] === "true"
        && productsInfo.hasOwnProperty("orderEndDate")
    ) {
        return parseInt(productsInfo["orderEndDate"], 10) <= new Date().getTime();
    }

    return false;
};

export const setExpiredAccount = (productsInfo, license) => {
    if (productsInfo.orderState === "automatic") {
        return false;
    }
    if (productsInfo.orderState === "cancelled") {
        return true;
    }

    if (!isObjectEmpty(license)) {
        if (license.hasOwnProperty("state") && license.hasOwnProperty('remainingDays') && license.state !==  "automatic" && license.remainingDays < 0) {
            return true;
        }
    }

    return false;
};

export const setPausedAccount = (productsInfo) => {
    return productsInfo.hasOwnProperty('statusPaused') && productsInfo.statusPaused === true;
};

export const setUserWithoutActiveLicense = (user) => {
    return user.hasOwnProperty('memberState') && user.memberState === "no_license";
};
export const setInstanceProduct = (productsInfo) => {
    return productsInfo.hasOwnProperty('instanceProduct') && productsInfo.instanceProduct === "true";
};

export const setUsedSeats = (license) => {
    return license.hasOwnProperty("usedSeats") ? Number(license.usedSeats) : 0;
};

export const setIsFreeUser = (productsInfo) => {
    return productsInfo.hasOwnProperty('FreeProduct') && productsInfo.FreeProduct === "true";
};

export const setUserUpgradePayMode = (user, products) => {
	if (products.paymentMethod === "paypal"){
		/* Our current order is a paypal order */
		return 'payPal';
	}
	
    if (user.hasOwnProperty('properties')) {
    	/* We've had a stripe/pp subscription at some point (either currently or in the past) */
        if (user.properties.hasOwnProperty('stripeId')) {
            return 'payCC';
        }
        if (user.properties.hasOwnProperty('paypalSubscriptionId')) {
            return 'payPal';
        }
    }
    
    return null;
};

export const setDefaultEditingMode = (user) => {
    return user.hasOwnProperty('properties') && user.properties.hasOwnProperty('defaultEditingMode') ? user.properties.defaultEditingMode : null;
};

export const setSKUTerm = (productsInfo) => {
    return productsInfo.hasOwnProperty('sku_term') ? productsInfo.sku_term : null;
};

export const setPriceTestingGroup = (productsInfo) => {
    return productsInfo.hasOwnProperty('priceTestingGroup') ? productsInfo.priceTestingGroup : null;
};

export const setPriceTestingGroupValue = (productsInfo) => {
    return productsInfo.hasOwnProperty('priceTestingGroupValue') ? productsInfo.priceTestingGroupValue : null;
};

export const setPriceCampaignKey = (productsInfo) => {
    return productsInfo.hasOwnProperty('priceCampaignKey') ? productsInfo.priceCampaignKey : null;
};

export const setDeploymentEnv = (userContext) => {
    return userContext.hasOwnProperty('deploymentEnv') ? userContext.deploymentEnv : "";
};

export const setUsageAnalyticsWeb = (user) => {
    let useAnalyticsWeb = false;

    if(user.hasOwnProperty('privacyPreferences')) {
        user.privacyPreferences.forEach(function (element) {
            if (element.key === "usage_analytics_web") {
                if (element.hasOwnProperty("value")) {
                    useAnalyticsWeb = element.value;
                } else {
                    useAnalyticsWeb = element.defaultValue;
                }
            }
        })
    }

    return useAnalyticsWeb;
};

export const isPurchaseOrder = (orderStatus) => {
    return orderStatus === "purchaseOrder" || orderStatus === "promo";
};

export const getInstanceId = (user) => {
    return user.hasOwnProperty('instanceId') ? user.instanceId : 0;
};

export const getEnterpriseRole = (user) => {
    return user.hasOwnProperty('enterpriseAccountOwner') && user.enterpriseAccountOwner
        ? "admin"
        : user.hasOwnProperty('enterpriseRole')
            ? user.enterpriseRole
            : "";
};

export const setUseHTMLEditor = (isEnterpriseMember, instanceProperty, isEducation, isPurchaseOrder, instanceId, isProduction) => {
    let returnValue = null;

    if (isEnterpriseMember) {
        if(instanceProperty.hasOwnProperty('useHtmlEditor')) {
            if (instanceProperty.useHtmlEditor === "true") {
                returnValue = true;
            } else if (instanceProperty.useHtmlEditor === "false") {
                returnValue = false;
            } else if (isEducation) {
                returnValue = true;
            }
        } else if (isEducation) {
            returnValue = true;
        }
    }

    if(returnValue === null) {
        if(isEnterpriseMember && isPurchaseOrder && instanceId < 170000 && isProduction) {
            returnValue = instanceProperty.hasOwnProperty('useHtmlEditor') && instanceProperty.useHtmlEditor === "true";
        }

        if(returnValue === null) {
            returnValue = !(instanceProperty.hasOwnProperty('useHtmlEditor') && instanceProperty.useHtmlEditor === "false")
        }
    }

    /* Allows for testing flash in test */

    if(!isProduction && instanceProperty.useHtmlEditor === "false") {
        returnValue = false
    }

    return returnValue;
};

export const setUserPrivacyPreferences = (user) => {
    if(user.hasOwnProperty('privacyPreferences')) {
        return user.privacyPreferences;
    }
    return [];
};

export const setSeatPrice = (productInfo) => {
    return productInfo.hasOwnProperty('Seat Price') ? productInfo['Seat Price'] : 0;
};
export const setCurrentSeats = (productInfo) => {
    return productInfo.hasOwnProperty('Seats') ? productInfo.Seats : 0;
};
export const setUserEssentialsInfo = (productInfo) => {
    return productInfo.hasOwnProperty('essentialsInfo') ? productInfo.essentialsInfo : null;
};

export const setLastOrderId = (productInfo) => {
    return productInfo.hasOwnProperty('orderId') ? productInfo.orderId : null;
};

export const setProductInformationProperties = (app, productsInfo) => {
    app.orderState = getOrderState(productsInfo);
    app.orderEndDate = getOrderEndDate(productsInfo);
    app.lastOrderId = setLastOrderId(productsInfo);
    app.vertical = getVertical(productsInfo);
    app.previousTrialOwner = getPreviousTrialOwner(productsInfo);
    app.productCode = getProductCode(productsInfo);
    app.termDurationMonths = getTermDurationMonths(productsInfo);
    app.purchasableProducts = setPurchasableProducts(productsInfo);
    app.disableAccountUpgrades = disableAccountUpgrades(productsInfo);
    app.freeProduct = setIsFreeUser(productsInfo);
    app.skuTerm = setSKUTerm(productsInfo);
    app.seatPrice = setSeatPrice(productsInfo);
    app.currentSeats = setCurrentSeats(productsInfo);
    app.essentialsInfo = setUserEssentialsInfo(productsInfo);
    app.accountType = setAccountType(productsInfo);
    app.professionalTrial = setProfessionalTrial(productsInfo);
    app.purchasableTrialProducts = setPurchasableTrialProducts(productsInfo);
    app.androidSubscription = productsInfo.androidSubscription;
    app.itunesSubscription = productsInfo.itunesSubscription;
    app.remainingTrialDays = setRemainingTrialDays(productsInfo, null);

    CurrentProduct.getInstance().setVertical(getVertical(productsInfo));

    /* Also keep a reference to the raw products info object */
    app.productsInfo = productsInfo;
};

export const updateLicenseProperties = (app, license) => {
    app.isMemberTrial = isMemberTrial(license, app.orderState);
    if(license.hasOwnProperty('remainingDays')) {
        app.remainingTrialDays = license.remainingDays;
    }
};

export const setShouldSeeSegmentation = (app, user) => {
    if (loadedInIframe()) {
        // upgrade paths should not go through segmentation
        return false;
    }
    if (app.userHasTrialProduct) {
        return true;
    }
    if (user.hasOwnProperty('properties') && user.properties.hasOwnProperty('shouldSeeSegmentation')) {
        return user.properties.shouldSeeSegmentation === "true";
    }

    return true;
};

export const setBelongsToPlayPositSpace = (products) => {
    return products.hasOwnProperty('belongsToPlayPositSpace') && products.belongsToPlayPositSpace === true;
};

export const setShowPremiumTemplates = () => {
    if (loadedInIframe()) {
        let hasPremium = URLHelper.getSearchParameterValue('premium');
        if (hasPremium && hasPremium === true) {
            return true;
        }
    }
    return false;
};

export const setPremiumTemplateProducts = () => {
    if (loadedInIframe()) {
        let strProducts = URLHelper.getSearchParameterValue('availableProducts');
        if (strProducts && strProducts.length) {
            return strProducts.split(',');
        }
    }
    return null;
};

export const setUserSegmentationInfo = (user) => {
    if (user.hasOwnProperty('segmentationInfo') && user.segmentationInfo.hasOwnProperty('segment')) {
        return user.segmentationInfo;
    }
    return null;
};

export const setCCRequiredForTrial = (user) => {
    if (user.hasOwnProperty('properties') && user.properties.hasOwnProperty('ccRequiredForTrial')) {
        return user.properties.ccRequiredForTrial === "true";
    }

    return false;
}

export const getRecaptchaSiteKey = () => {
    if (isProduction()) {
        return RECAPTCHA_SITE_KEY_PROD;
    } else {
        return RECAPTCHA_SITE_KEY_DEV;
    }
}

export const updateCheckoutUserContextActiveState = (isActivated) => {
    let checkoutAppContext = window.hasOwnProperty('checkoutAppContext') ? window.checkoutAppContext : {};
    if (checkoutAppContext.hasOwnProperty('activated')) {
        window.checkoutAppContext.activated = isActivated;
        DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'activationRequired', !isActivated);
    }
}
export const updateCheckoutUserContextLoginState = (isLoggedIn) => {
    let checkoutAppContext = window.hasOwnProperty('checkoutAppContext') ? window.checkoutAppContext : {};
    if (checkoutAppContext.hasOwnProperty('authenticated')) {
        window.checkoutAppContext.authenticated = isLoggedIn;
        DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'isAuth', checkoutAppContext.authenticated);
    }
}
export const isBlockedDomain = (user, productsInfo) => {
    const userEmail = user.hasOwnProperty('email') ? user.email : '';

    if (productsInfo.hasOwnProperty('FreeProduct') && productsInfo.FreeProduct === "true") {
        // special hard-coded case for lausd email addresses
        if (userEmail.indexOf('@mymail.lausd.net') !== -1) {
            window.location.href = '/lausd-student';
            return true;
        } else if (userEmail.indexOf('@lausd.net') !== -1) {
            window.location.href = '/lausd';
            return true;
        }
    }

    return productsInfo.hasOwnProperty('domainStatus') && productsInfo.domainStatus === "blocked";
}
