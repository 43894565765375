import LocalStorageHelper from "./LocalStorageHelper";
import {
    LOCAL_STORAGE_APP_KEY,
    LOCAL_STORAGE_PRODUCTS_KEY,
} from "../constants/LocalStorage";
import {
    PLAN_SELECTED_TIER_BUSINESS,
    PLAN_SELECTED_TIER_EDUCATION,
    PLAN_SELECTED_TIER_PERSONAL
} from "../constants/Plans";
import {
    getEmailVerified,
    getIsExpiredTrial, getProductCode,
    getVertical,
    isAuth,
    isFreeUser,
    isInHub
} from "./AppHelper";
import CurrentProduct from "../models/CurrentProduct";
import {WEVIDEO_PLANS} from "../constants/AppGlobal";
import DataStore from "./DataStore";

const MOST_RECENT_PRICE_STRUCTURE = "EDU_PRICE_STRUCTURE_JAN_2024";
const EDU_PRICE_STRUCTURES = {
    "EDU_PRICE_STRUCTURE_LEGACY": [
        [1, 30, 199 / 30],
        [31, 100, 5.6],
        [101, 200, 4.95],
        [201, 300, 4.45],
        [301, 400, 4.1],
        [401, 500, 3.75],
        [501, 750, 3.4],
        [751, 1000, 3.1]
    ],
    "EDU_PRICE_STRUCTURE_FEB_2019": [
        [1, 30, 299 / 30],
        [31, 100, 8],
        [101, 200, 7],
        [201, 300, 6],
        [301, 400, 5],
        [401, 500, 4],
        [501, 750, 3.25],
        [751, 1000, 2.85]
    ],
    "EDU_PRICE_STRUCTURE_JAN_2021": [
        [1, 30, 299 / 30],
        [31, 100, 8.4],
        [101, 200, 7.35],
        [201, 300, 6.30],
        [301, 400, 5.25],
        [401, 500, 4.20],
        [501, 750, 3.4125],
        [751, 1000, 2.9925]
    ],
    "EDU_PRICE_STRUCTURE_JAN_2022": [
        [1, 30, 299 / 30],
        [31, 100, 8.904],
        [101, 200, 7.7175],
        [201, 300, 6.552],
        [301, 400, 5.4075],
        [401, 500, 4.326],
        [501, 750, 3.514875],
        [751, 1000, 3.082275]
    ],
    "EDU_PRICE_STRUCTURE_JAN_2023": [
        [1, 30, 299 / 30],
        [31, 100, 9.3492],
        [101, 200, 8.103375],
        [201, 300, 6.8796],
        [301, 400, 5.677875],
        [401, 500, 4.5423],
        [501, 750, 3.69061875],
        [751, 1000, 3.23638875]
    ],
    "EDU_PRICE_STRUCTURE_JAN_2024": [
        [1, 30, 374 / 30],
        [31, 100, 12.270875],
        [101, 200, 10.635625],
        [201, 300, 9.0295],
        [301, 400, 7.45225],
        [401, 500, 5.96175],
        [501, 750, 4.843875],
        [751, 1000, 4.24775],
        [1001, 1250, 3.726125],
        [1251, 5000, 3.3535],
        [5000, 7500, 3.055375],
        [7501, 10000, 2.757375],
        [10001, 15000, 2.321375],
        [15001, 20000, 1.863125],
        [20001, 30000, 1.49775],
        [30001, 50000, 1.12325],
    ],
};

export const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateSeatPrice = (number) => {
    return calculateSeatPriceForInterval(0, number);
};

export const calculateSeatPriceForInterval = (start, end) => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY) || null;
    let priceStructure = MOST_RECENT_PRICE_STRUCTURE;

    if (typeof app !== "undefined" && app) {
        if (!app.isTrial && app.hasLicense) {
            priceStructure = app.licensePricingStructure || null;

            if (priceStructure === null) {
                priceStructure = "EDU_PRICE_STRUCTURE_LEGACY";
            }
        }
    } else if (window.licensePriceStructure) {
        priceStructure = window.licensePriceStructure;
    }

    if (!EDU_PRICE_STRUCTURES[priceStructure]) {
        priceStructure = MOST_RECENT_PRICE_STRUCTURE;
    }

    return calculateSeatPriceForIntervalWithPriceStructure(start, end, priceStructure);
};

export const calculateSeatPriceForIntervalWithPriceStructure = (start, end, priceStructure) => {
    const PRICE_TIERS = EDU_PRICE_STRUCTURES[priceStructure];
    let sum = 0;

    for (let i = 0; i < PRICE_TIERS.length; i++) {
        let from = PRICE_TIERS[i][0], to = PRICE_TIERS[i][1], unitPrice = PRICE_TIERS[i][2];

        if (start > to) {
            continue;
        } else if (end < from) {
            continue;
        }

        sum += ((Math.min(end, to) - Math.max(from, start)) + 1) * unitPrice;
    }

    return sum;
};

export const setUserTier = (tier) => {
    LocalStorageHelper.update(LOCAL_STORAGE_PRODUCTS_KEY, 'tier', tier);
};

export const getUserTier = () => {
    const product = LocalStorageHelper.get(LOCAL_STORAGE_PRODUCTS_KEY);

    return (product !== null && product.hasOwnProperty('tier')) ? product.tier : PLAN_SELECTED_TIER_PERSONAL;
};


export const isProductK12 = (code) => {
    return code !== "" && code.indexOf("k12") !== -1
};

export const isEducation = (productCode) => {
    return (
        getVertical() === "education"
        || (
            typeof productCode !== 'undefined'
            && (
                isProductK12(productCode)
                || productCode.indexOf("highered") !== -1
                || productCode.indexOf("wv_teacher") !== -1
                || productCode.indexOf("wv_edu_trial") !== -1
            )
        )
    );
};

export const productIsEducation = (productCode) => {
    return (
        typeof productCode !== 'undefined'
        && (
            isProductK12(productCode)
            || productCode.indexOf("highered") !== -1
            || productCode.indexOf("wv_teacher") !== -1
            || productCode.indexOf("wv_edu_trial") !== -1
        ));
}

export const isTeacherPlan = (productCode) => {
    return productCode.indexOf("wv_teacher") !== -1;
}

export const isBiz = (productCode) => {
    return productCode.indexOf("wv_biz") !== -1;
};

export const isClassroomPlan = (productCode) => {
    return productCode === 'wv_k12_12m';
};

export const isBusinessPlan = (code) => {
    return code.indexOf("bizoct") !== -1;
};

export const isEssentialsPlan = (code) => {
    return code.indexOf("_plus_") !== -1;
};

export const checkIfProductIsTierBusiness = (productCode) => {
    return (
        typeof productCode !== 'undefined'
        && (
            isBusinessPlan(productCode)
            || productCode === 'wv_bizindiv_1m'
            || productCode === 'wv_bizindiv_12m'
        )
    );
};
export const isCreatorPlan = (code) => {
    return code.indexOf("wv_individual") !== -1;
}
export const isProfessionalPlan = (code) => {
    return code.indexOf("wv_bizindiv") !== -1;
}

export const isAnnualProfessionalPlan = (code) => {
    return code.indexOf("wv_bizindiv_12m") !== -1;
}

export const isPowerPlan = (code) => {
    return code.indexOf("wv_single") !== -1;
}

export const isUnlimitedPlan = (code) => {
    return code.indexOf("wv_unloct17") !== -1;
}

export const getUserPlansTab = (productCode) => {
    if (checkIfProductIsTierBusiness(productCode)) {
        return PLAN_SELECTED_TIER_BUSINESS;
    }

    let tier = getUserTier();
    if (tier !== PLAN_SELECTED_TIER_PERSONAL && tier !== PLAN_SELECTED_TIER_EDUCATION && tier !== PLAN_SELECTED_TIER_BUSINESS) {
        tier = PLAN_SELECTED_TIER_PERSONAL;
    }

    if (isEducation(productCode)) {
        tier = PLAN_SELECTED_TIER_EDUCATION;
    }

    return tier;
};

export const getTrialWasUsed = () => {
    const app = DataStore.getInstance().get(LOCAL_STORAGE_APP_KEY);

    if(!app) return;

    return app.previousTrialOwner ? app.previousTrialOwner : false;
};

// reference to old code: UserSession.setPersistentValue("selectedP", "");
export const setSelectedProcess = (process) => {
    LocalStorageHelper.update(LOCAL_STORAGE_PRODUCTS_KEY, 'selectedProcess', process)
};

// reference to old code: UserSession.getUserProperty("selectedP")
export const getSelectedProcess = () => {
    const product = LocalStorageHelper.get(LOCAL_STORAGE_PRODUCTS_KEY);

    return product.hasOwnProperty('selectedProcess') ? product.selectedProcess : '';
};

export const getTierByProductCode = (productCode) => {

    // if the product code is undefined or null return tier personal
    if(typeof productCode === "undefined" || productCode === null) {
        return PLAN_SELECTED_TIER_PERSONAL;
    }

     if (isEducation(productCode)) {
         return PLAN_SELECTED_TIER_EDUCATION;
     }
     if (checkIfProductIsTierBusiness(productCode)) {
         return PLAN_SELECTED_TIER_BUSINESS;
     }
    return PLAN_SELECTED_TIER_PERSONAL;
};

export const showTierSelector = () => {
    if(isAuth() && !isInHub()) {
        // loaded from website as logged in user
        return true;
    }
    if (isFreeUser() || !isAuth()) {
        return true;
    }
    if (isAuth()) {
        // all dc users should see the tier selector
        // users segmented as k12 educators or with an education valid email address will be defaulted to the education tab
        const currentProductCode = getProductCode();
        if (isDCUser(currentProductCode)) {
            return true;
        }
    }
    return false;
};

export const getFreeProductInfo = (productCode) => {
    let name;

    switch(productCode) {
        case WEVIDEO_PLANS.FREE_PLAN:
            name = 'Free Plan';
            break;
        case 'wv_edu_trial':
            name = 'Education Trial';
            break;
        case 'wv_bizoct17_trial':
            name = 'Business Trial';
            break;
        case 'wv_bizindiv_trial':
            name = 'Business Trial';
            break;
        default:
            name = 'Product does not exist';
    }

    return {
        name: name,
        displayName: name,
        productCode: productCode,
        planPrice: 0,
        priceMonth: 0,
        annually: false,
    }
};

export const getEssentialsProductDisplayName = () => {
    return 'Essentials library';
};

export const isExpiredTrial = () => {
    return getIsExpiredTrial();
};

export const trialEmailVerificationRequired = () => {
    return CurrentProduct.getInstance().getFeatures().hasFeatures("TrialEmailVerification") && !getEmailVerified();
};

export const isDCUser = (productCode) => {
    return !isEducation(productCode);
}

export const productIsTrial = (productCode) => {
    return productCode.indexOf("trial") !== -1;
}
