import { call, put } from "redux-saga/effects";

import {InitAppTypes} from "../redux/InitAppReducer";
import * as InitAppHelper from "../helpers/InitAppHelper";
import {isEducation} from "../helpers/PlansHelper";
import {getAppVersionNumber, isProduction} from "../helpers/GeneralHelper";
import {
    LOCAL_STORAGE_APP_KEY, LOCAL_STORAGE_INSTANCE_INFO_KEY,
} from "../constants/LocalStorage";
import CurrentProduct from "../models/CurrentProduct";
import ActiveCampaigns from "../models/ActiveCampaigns";
import DataStore from "../helpers/DataStore";
import {TrackJS} from "trackjs";
import {INSTANCE_NAME, WEVIDEO_PLANS} from "../constants/AppGlobal";
import {initTrackJs, updateUserData} from "../services/track/ErrorTracker";
import {setPremiumTemplateProducts, setShowPremiumTemplates} from "../helpers/InitAppHelper";
import URLHelper from "../helpers/URLHelper";

const initApp = function* (api, action) {
    try {
        let app = {}, user= {};
        let userIsAuthenticated = window.hasOwnProperty('loggedInUser') ? window.loggedInUser : null;
        let checkoutAppContext = window.hasOwnProperty('checkoutAppContext') ? window.checkoutAppContext : {};
        let userIsLoggedIn = userIsAuthenticated;
        if (userIsLoggedIn === null || userIsLoggedIn === false) {
            if (checkoutAppContext.hasOwnProperty('authenticated')) {
                userIsLoggedIn = checkoutAppContext.authenticated;
            }
        }

        DataStore.getInstance().clearDataStore();

        /*
		 * Default to EU, since that's where we have the strictest privacy
		 * regulations. We're not currently using these values unless the user
		 * is logged in, so not having a fallback here
		 */
        app.isGdprRegion = true;
        app.isClientUs = false;

        if (
            (window.hasOwnProperty('loggedInUser') && userIsAuthenticated === true) ||
            userIsLoggedIn === true || userIsLoggedIn === null
        ) {

            const responseAuthenticate =  yield call(api.getUser);

            if (!responseAuthenticate.data.success) {
            	/* No authenticated user */
                app.isAuth = window.hasOwnProperty('loggedInUser') ? window.loggedInUser : false;
            } else {
            	/* User is authenticated, fetch other relevant information */
                let license = {}, productsInfo = {}, userLocation = {}, userContext = {}, instanceProperty = DataStore.getInstance().get(LOCAL_STORAGE_INSTANCE_INFO_KEY);
                let promises = [], invalidTeacherEmail = false, needsEmailValidation = false, invalidEduEmail = false;
                user = responseAuthenticate.data;

                if(user['enterpriseMember'] || user['enterpriseAccountOwner']) {
            		promises.push(api.getUserLicense().then(evt => {
            			license = evt.data;
                        app.hasLicense = true;
                        app.licensePricingStructure = license['licensePricingStructure'];
                        app.maxSeats = license['maxSeats'];
            		}));
                } else {
                    app.hasLicense = false;
                }

                app.teacherEmailWasValidated = false;
                let urlParams = URLHelper.getUrlParamsFromWindowObj(window.location);
                app.selectedProduct = InitAppHelper.setSelectedProduct(user, urlParams);
                app.activationRequired = InitAppHelper.activationRequired(user);
                const teacherPlan = WEVIDEO_PLANS.EDUCATION_TEACHER_PLAN_Y;
                const classroomTrial = WEVIDEO_PLANS.EDUCATION_TRIAL;
                let weNeedToValidate = false;

                if (app.selectedProduct === teacherPlan && app.activationRequired === true) {
                    weNeedToValidate = true;
                }
                if (app.selectedProduct === classroomTrial && app.activationRequired === true) {
                    weNeedToValidate = true;
                }

                if (weNeedToValidate === true || (urlParams.validateEduEmail && urlParams.validateEduEmail === 'true')) {
                    // for the teacher plan we first need to validate the email
                    // call is only made for sign-ups, where user activation is required
                    needsEmailValidation = true;
                    promises.push(api.verifyTeacherEmail().then(evt => {
                        if (evt.data && evt.data.hasOwnProperty('success')) {
                            invalidTeacherEmail = evt.data.success === false;
                            if (urlParams.validateEduEmail && urlParams.validateEduEmail === 'true') {
                                invalidEduEmail = evt.data.success === false;
                            }
                            app.teacherEmailWasValidated = true;
                        }
                    }));
                } else {
                    app.invalidTeacherEmail = false;
                }
                /* fetch user location */
                promises.push(api.getUserLocation().then(response => {
                    if (response.hasOwnProperty('data')) {
                        userLocation = response.data;
                    }
                }));

                /* Fetch product information */
                promises.push(api.getProductsInfo(app).then(data => {
                	productsInfo = data;
                }));

                /* Context */
                promises.push(api.getUserContext().then(evt => {
                	userContext = evt.data;
                }));

                /* Instance info */
                if (instanceProperty === null) {
            		promises.push(api.getInstanceProperty().then(evt => {
            			instanceProperty = evt.data;
                        DataStore.getInstance().set(LOCAL_STORAGE_INSTANCE_INFO_KEY, instanceProperty);
            		}));
                }

                /* Wait for all calls to complete */
                yield Promise.all(promises);

                if (needsEmailValidation === true && invalidTeacherEmail === true) {
                    // we need to go to the invalid email
                    app.invalidTeacherEmail = invalidTeacherEmail;
                    app.invalidEduEmail = invalidEduEmail;
                }
                window.instanceInfo = instanceProperty;

                app.isAuth = true;

                // Data From getUser API call
                app.isEnterpriseAdmin = InitAppHelper.setIsEnterpriseAdmin(user);
                app.isUserEnterpriseMember = InitAppHelper.isUserEnterpriseMember(user);
                app.userId = InitAppHelper.setUserId(user);
                app.userName = InitAppHelper.userUserName(user);
                app.firstName = InitAppHelper.userFirstName(user);
                app.lastName = InitAppHelper.userLastName(user);
                app.userEmail = InitAppHelper.userEmail(user);
                app.visitedNewHub = InitAppHelper.visitedNewHub(user);
                app.useNewPlans = InitAppHelper.utilizeNewPlans(user);
                app.usageAnalyticsWeb = InitAppHelper.setUsageAnalyticsWeb(user);
                app.instanceId = InitAppHelper.getInstanceId(user);
                app.enterpriseRole = InitAppHelper.getEnterpriseRole(user);
                app.userUpgradePayMode = InitAppHelper.setUserUpgradePayMode(user, productsInfo);
                app.privacyPreferences = InitAppHelper.setUserPrivacyPreferences(user);
                app.defaultEditingMode = InitAppHelper.setDefaultEditingMode(user);
                app.emailVerified = InitAppHelper.setEmailVerified(user);
                app.desiredInstanceName = InitAppHelper.setDesiredInstanceName(user);
                app.targetedPricingCampaign = InitAppHelper.setTargetedPricingCampaign(user);
                app.enterpriseMemberInstance = InitAppHelper.setEnterpriseMemberInstance(user);
                app.sessionPropertiesPageUrl = InitAppHelper.setSessionPropertiesPageUrl(user);
                app.sessionPropertiesSignUpPage = InitAppHelper.setSessionPropertiesSignUpPage(user);
                app.ssoType = InitAppHelper.setSSOType(user);
                app.segmentationInfo = InitAppHelper.setUserSegmentationInfo(user);

                app.selectedBuyContext = InitAppHelper.setSelectedBuyContext(user);
                app.selectedTier = InitAppHelper.setSelectedTier(user);

                app.userPurpose = InitAppHelper.setUserPurpose(user);
                // Data From getUserContext API call
                app.deploymentEnv = InitAppHelper.setDeploymentEnv(userContext);

                if(userContext.hasOwnProperty('activeCampaignData')) {
                    ActiveCampaigns.getInstance().setCampaigns(userContext.activeCampaignData)
                }

                // Data From getUserLicense API call
                app.usedSeats = InitAppHelper.setUsedSeats(license);

                // Data From Others
                app.userHasPaymentMethodSet = InitAppHelper.userHasPaymentMethodSet(user, productsInfo);
                app.userHasTrialProduct = InitAppHelper.userHasTrialProduct(user, productsInfo);
                app.isMemberTrial = InitAppHelper.isMemberTrial(license, app.orderState);
                app.isTrial = app.isEnterpriseAdmin && app.isMemberTrial;
                app.shouldViewUpgrade = InitAppHelper.shouldViewUpgrade(user, app.disableAccountUpgrades);
                app.remainingTrialDays = InitAppHelper.setRemainingTrialDays(productsInfo, license);
                app.isExpiredTrial = InitAppHelper.setExpiredTrial(productsInfo, license, instanceProperty, app.isTrial);
                app.isExpiredAccount = InitAppHelper.setExpiredAccount(productsInfo, license);
                app.isPausedAccount = InitAppHelper.setPausedAccount(productsInfo);
                app.isUserWithoutActiveLicense = InitAppHelper.setUserWithoutActiveLicense(user);
                app.hasInstanceProduct = InitAppHelper.setInstanceProduct(productsInfo);
                app.isPurchaseOrder = InitAppHelper.isPurchaseOrder(app.orderState);
                app.canEditSSOCredentials = InitAppHelper.setCanEditSSOCredentials(instanceProperty);
                app.canEditCredentials = InitAppHelper.setCanEditCredentials(instanceProperty);
                app.showPremiumTemplates = setShowPremiumTemplates();
                app.premiumTemplateProducts = setPremiumTemplateProducts();
                app.belongsToPlayPositSpace = InitAppHelper.setBelongsToPlayPositSpace(productsInfo);
                
                if (user.hasOwnProperty('activeTests') && user.activeTests.length > 0) {
                    app.activeTests = user.activeTests;
                }

                app.useHTMLEditor = InitAppHelper.setUseHTMLEditor(
                    app.isUserEnterpriseMember,
                    instanceProperty,
                    isEducation(app.productCode),
                    InitAppHelper.isPurchaseOrder(app.orderState),
                    app.instanceId,
                    isProduction()
                );
                if (userLocation.hasOwnProperty('stateCode')) {
                    app.userStateCode = userLocation.stateCode;
                }
                if (userLocation.hasOwnProperty('stateName')) {
                    app.userStateName = userLocation.stateName;
                }

                //TODO: For the API endpoint we also need the flags property that it's used bellow


                if(productsInfo && productsInfo.hasOwnProperty('Features')) {
                    CurrentProduct.getInstance().setFeatures(productsInfo.Features);
                }

                if(productsInfo && productsInfo.hasOwnProperty('productCode')) {
                    CurrentProduct.getInstance().setCode(productsInfo.productCode);
                }

                if(productsInfo && productsInfo.hasOwnProperty('4KExportPrice')) {
                    CurrentProduct.getInstance().setFourKExportPrice(productsInfo['4KExportPrice']);
                }

                if(productsInfo && productsInfo.hasOwnProperty('ExportPrice')) {
                    CurrentProduct.getInstance().setHDExportPrice(productsInfo['ExportPrice']);
                }

                InitAppHelper.isBlockedDomain(user, productsInfo);
            }
        } else {
            /* istanbul ignore next */
            app.isAuth = window.hasOwnProperty('loggedInUser') ? window.loggedInUser : false;
            if (window.hasOwnProperty('activeTests') && window.activeTests.length > 0) {
                app.activeTests = window.activeTests;
            }
        }

        if (app.isAuth && isProduction()) {
            initTrackJs('checkout-prod', getAppVersionNumber());
        }

        const sessionStatus =  yield call(api.getSessionStatus);
        if (sessionStatus.hasOwnProperty('data') && sessionStatus.data.hasOwnProperty('eu')) {
            app.isGdprRegion = !!sessionStatus.data.eu;
        }
        if (sessionStatus.hasOwnProperty('data') && sessionStatus.data.hasOwnProperty('countryCode')) {
            app.isClientUs = (sessionStatus.data.countryCode === "US");
        }
        if (!app.hasOwnProperty('activeTests')) {
            if (sessionStatus.hasOwnProperty('data') && sessionStatus.data.hasOwnProperty('activeTests') && sessionStatus.data.activeTests.length > 0) {
                app.activeTests = sessionStatus.data.activeTests;
            }
        }

        if(window.hasOwnProperty('activeCampaignData')) {
            ActiveCampaigns.getInstance().setCampaigns(window.activeCampaignData);
        }
        if (window.hasOwnProperty('promoId') && !app.targetedPricingCampaign) {
            app.targetedPricingCampaign = window.promoId;
        } else if (window.hasOwnProperty('targetedPricingCampaign') && !app.hasOwnProperty('targetedPricingCampaign')) {
            app.targetedPricingCampaign = window.targetedPricingCampaign;
        }

        if(user.hasOwnProperty('flags') && user.flags.hasOwnProperty('gdprRegion')) {
            app.isGdprRegion = user.flags.gdprRegion;
            app.isClientUs = (user.flags.country === "US");
            app.userCountryCode = user.flags.country;
        }
        if (window.hasOwnProperty('isClientUs')) {
            // for the plans page rendered on website, we get the isClientUs variable from window object (FreeMarker variable isUs)
            app.isClientUs = window.isClientUs;
        }

        // will default to true if nothing is set up in the index.html file
        app.hasGtmEnabled = checkoutAppContext.hasOwnProperty('gtmEnabled') ? checkoutAppContext.gtmEnabled : true;
        app.feideLogin = checkoutAppContext.hasOwnProperty('feideLogin') ? checkoutAppContext.feideLogin : false;

        if (TrackJS.isInstalled()) {
            let userId = 0;
            if (app && app.hasOwnProperty('userId')) {
                userId = app.userId;
            }
            if (userId === 0) {
                userId = '0_' + Date.now();
            }
            updateUserData(userId, INSTANCE_NAME);
        }

        app.shouldSeeSegmentation = InitAppHelper.setShouldSeeSegmentation(app, user);
        app.ccRequiredForTrial = InitAppHelper.setCCRequiredForTrial(user);
        app.showReceiptOnFirstSegmentationPage = false;
        app.isInHub = window.hasOwnProperty('isInHub') ? window.isInHub : true;
        app.showCampaignBanner = window.hasOwnProperty('showCampaignBanner') ? window.showCampaignBanner : true;

        DataStore.getInstance().set(LOCAL_STORAGE_APP_KEY, app);

        yield put({type: InitAppTypes.INIT_APP_SUCCESS, app: app});
    } catch (error) {
        yield put({type: InitAppTypes.INIT_APP_FAILURE, error: error.message})
    }
};

export {
    initApp
};
